<template>
  <div class="download">
    <Banner>
      <img src="@/assets/images/logo.svg" />
    </Banner>
    <div class="wrap">
      <div class="download-item left">
        <img class="img" src="@/assets/images/download/downloadBag.svg" />
      </div>
      <div class="download-item right">
        <img class="img-icon" src="@/assets/images/download/downloadIcon.svg" />
        <div class="download-text-content">
          <h3 class="text-content-title">KCheck Client for Mac</h3>
          <ul>
            <li><span class="arrows" />5 free usages per day</li>
            <li><span class="arrows" />Efficient detection</li>
          </ul>
          <button class="download-btn">
            <a
              href="https://kcheck.oss-cn-shenzhen.aliyuncs.com/mac_client/KCheck.zip"
            >
              <img src="@/assets/images/download/downloadBtnIcon.svg" />
              <span>Download</span>
            </a>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Banner from "@/components/common/Banner.vue";

export default defineComponent({
  name: "Download",
  components: {
    Banner,
  },
  setup() {
    return {};
  },
});
</script>

<style lang="less" scoped>
.download {
  display: flex;
  flex-direction: column;
  flex: 1;
  .nav-banner {
    background: #2a3e71;
  }
  .wrap {
    flex: 1;
    display: flex;
    padding-top: 290px;
    padding-bottom: 290px;
    .download-item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      &.left {
        img {
          margin-left: 40px;
        }
      }
      &.right {
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 58px;
        .img-icon {
          height: 200px;
          width: 200px;
          margin-left: 70px;
        }
        .download-text-content {
          text-align: left;
          margin-left: 44px;
          .text-content-title {
            padding: 0px;
            margin: 0px;
            margin-bottom: 16px;
            font-size: 48px;
            font-family: Abhaya Libre SemiBold-Regular, Abhaya Libre SemiBold;
            font-weight: 400;
            color: #000000;
            line-height: 56px;
          }
          ul {
            padding: 0px;
            margin: 0px;
            margin-left: 10px;
            li {
              padding: 0px;
              margin: 0px;
              list-style: none;
              display: flex;
              align-items: center;
              padding-top: 35px;
              .arrows {
                display: block;
                content: "";
                width: 0;
                border-width: 9px 9px 9px 16px;
                border-style: solid;
                border-color: transparent transparent transparent #000000;
                margin-right: 10px;
                margin-top: -2px;
              }
            }
          }
          .download-btn {
            background: none;
            outline: none;
            padding: 0px;
            margin: 0px;
            width: 238px;
            height: 72px;
            margin-top: 74px;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            overflow: hidden;
            border: 1px solid #000000;
            a {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              padding: 24px 58px;
              color: #000000;
            }
            img {
              margin-right: 6px;
            }
            span {
              margin-left: 6px;
            }
          }
        }
      }
    }
  }
}
</style>
